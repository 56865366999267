// Primary Colors
$omm-black: #000000;
$omm-midnight: #00263A;
$omm-darkblue-1: #004487;
$omm-darkblue-2: #153267;
$omm-blue: #005BB5;
$omm-accent-blue: #0071E1;

// Soft Blues
$omm-blue-soft-1: #F3F7FC;
$omm-blue-soft-2: #EBF1F9;
$omm-blue-soft-3: #E0EBF7;

// Soft Greys
$omm-grey-soft-1: #F7F9FB;
$omm-grey-soft-2: #EEF1F3;
$omm-grey-soft-3: #DDE3E7;

$omm-blue: #0057b8;
$soft-grey-03: #dde3e7;
$soft-grey-02: #eef1f3;
$omm-dark-blue-02: #163069;
$omm-midnight: #00263A;
$slate: #657786;
$black: #000;
$slate-dark: #2a2e31;
$soft-grey-01: #f7f9fb;
$dark-green-blue: #28724f;
$light-olive-green: #a9c23f;
$bluegreen: #007680;
$greyblue: #59bec9;
$teal-blue: #008eaa;
$cerulean: #008bce;
$reddish-orange: #ff671f;
$golden: #f2cd00;
$pale-orange: #ffb25b;
$ocher: #d29f13;
$strawberry: #ef3340;
$berry: #8a1538;
$darkblue: #04135f;
$cerise: #ce0f69;
$grape: #6e2b62;
$cool-grey: #a4a5a7;
$light-peach: #e4d5d3;
$dusky-purple: #86647a;
$omm-accent: #016de5;
$omm-dark-blue-01: #00418a;
$soft-blue-01: #f3f7fc;
$soft-blue-03: #e0ebf7;
$soft-blue-02: #ebf1f9;
$white: #fff;

// Responsive Breakpoints
$mobile: 414px;
$tablet: 768px;
$laptop: 960px;
$desktop: 1280px;

// Space
$space-xxxs: 8px;
$space-xxs: 16px;
$space-xs: 24px;
$space-sm: 32px;
$space-md: 40px;
$space-lg: 48px;
$space-xl: 56px;
$space-xxl: 64px;
$space-xxxl: 72px;

// Gutters
$gutter-sm: 12px;
$gutter-md: 16px;
$gutter-lg: 20px;

// Numeric Spacing
$space-8: 8px;
$space-12: 12px;
$space-16: 16px;
$space-20: 20px;
$space-22: 22px;
$space-24: 24px;
$space-32: 32px;
$space-40: 40px;
$space-48: 48px;
$space-56: 56px;
$space-64: 64px;
$space-72: 72px;

// Radius
$radius-sm: 8px;
$radius-md: 12px;
$radius-lg: 16px;