$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.umbraco-forms {

    &-submitmessage {
        text-align: center;
        display: block;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.4;
        letter-spacing: -0.5px;
        color: $slate-dark;
        max-width: 600px;
        margin: 0 auto;
    }

    // to override style.min.css
    &-field.checkbox,
    &-field.multiplechoice,
    &-field.singlechoice {
        input {
            border: 1px solid $omm-blue !important;
            display: inline-block !important;
            height: 18px !important;
            width: 18px !important;
        }
    }

    &-field.multiplechoice {
        .checkboxlist {
            float: none !important;
            margin: 0.5em 0 !important;
            padding: 0 !important;

            label {
                cursor: pointer;
                clear: none !important;
                float: none !important;
            }
        }
    }

    &-field.singlechoice {
        .radiobuttonlist {
            margin: 0.5em 0;

            label {
                cursor: pointer;
            }
        }
    }

    &-field.dataconsent {
        label {
            cursor: pointer;
        }

        .umbraco-forms-field-wrapper {
            margin: 0.5em 0;
        }
    }

    // to override _checkbox.scss
    &-form .checkbox {
        cursor: initial;
        display: block;
        padding: 0;
        position: initial;
    }

    font-family: 'proxima-nova',
    sans-serif;

    &-caption {
        color: $omm-blue;
        font-size: 28px;
        font-weight: 300;
        font-style: normal;
        letter-spacing: -1px;
        text-align: center;
        margin-bottom: 27px;
    }

    &-field {
        margin-bottom: 24px;
    }

    &-tooltip {
        font-size: 14px;
        color: $slate-dark;
    }

    &-form .field-validation-error {
        margin-top: 6px !important;
        color: $strawberry !important;
    }

    &-field-wrapper {
        label {
            font-family: 'proxima-nova', sans-serif;
            color: black;
            font-size: 16px;
            font-weight: 300 !important;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: -0.3px;
        }
    }

    &-label {
        font-family: 'proxima-nova', sans-serif;
        color: black;
        font-size: 16px;
        font-weight: 300 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: -0.3px;
    }

    &-form input[type="text"],
    &-form input[type="number"],
    &-form input[type="email"],
    &-form input[type="tel"],
    &-form input[type="file"],
    &-form textarea,
    &-form select,
    &-form input.text {
        border-radius: 5px;
        border: solid 1px $soft-grey-03;
        background-color: white;
        font-family: 'proxima-nova', sans-serif;
        font-size: 16px;
        letter-spacing: -0.48px;
        margin: 0.5em 0;
        min-height: 46px;
        min-width: 100% !important;
        padding: 13px 17px !important;
        width: 100% !important;

        &::placeholder {
            font-size: 16px;
            letter-spacing: -0.48px;
            text-align: left;
            color: #aab8c2;
        }
    }

    &-form input[type="checkbox"],
    &-form input[type="radio"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: white;
        border: 1px solid $omm-blue;
        border-radius: 2px;
        cursor: pointer;
        height: 18px;
        margin: 0;
        opacity: 1;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 18px;
        z-index: 0;

        &::after {
            left: 5px;
            top: 1px;
            content: '';
            position: absolute;
            display: none;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }

        &:checked:after {
            display: block;
        }

        &:focus {
            background: white;
        }

        &:checked,
        &:checked:focus {
            background: $omm-blue;
        }

        &:disabled {
            background: white;
            border: 1px solid $slate;
            pointer-events: none;
        }
    }

    &-form input[type="radio"] {
        border-color: $slate !important;
        border-radius: 50%;

        &::after {
            display: none;
        }

        &:checked {
            border-color: transparent !important;
            background: $omm-blue border-box;
            box-shadow: 0 0 0 2px inset #fff;

            &::after {
                display: none;
            }
        }
    }

    &-form input[type="file"] {
        padding-bottom: 32px !important;
        padding-top: 32px !important;
        text-align: center;
    }

    &-form textarea {
        height: 127px !important;
    }

    &-form .dropdown {
        .umbraco-forms-field-wrapper {
            position: relative;

            &::before {
                border: 2px solid $omm-blue;
                border-left-width: 0;
                border-top-width: 0;
                color: $omm-blue;
                content: "";
                height: 12px;
                pointer-events: none;
                position: absolute;
                right: 18px;
                top: calc(50% - 3px);
                transform: translateY(-50%) rotate(45deg);
                width: 12px;
                z-index: 1;
            }
        }
    }

    &-form select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        padding-right: 46px !important;
        position: relative;
        text-overflow: ellipsis;

        &:hover,
        &:focus {
            cursor: pointer;
            outline: none;
        }
    }

    &-form input.contourError,
    &-form textarea.contourError,
    &-form input.input-validation-error,
    &-form textarea.input-validation-error {
        background: white !important;
        border-color: $strawberry !important;
    }

    &-form span.contourError,
    &-form span.field-validation-error {
        color: $strawberry !important;
        background: transparent !important;
        padding-left: 0px;
        font-size: 14px;
        letter-spacing: -0.48px;
        margin-top: 0px;
        margin-bottom: 15px;
        display: block;
    }

    &-fieldset {
        .row-fluid {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: center;
            gap: 12px;

            @each $col in $columns {
                .col-md-#{$col} {
                    width: 100%;

                    @media (min-width: $tablet) {
                        width: calc((100% / 12 * $col) - 12px);
                    }
                }
            }
        }
    }
}

.umbraco-forms-navigation {
    padding: 0px;

    input[type="submit"] {
        margin: 0 auto;
        min-width: 142px;
    }
}

.umbraco-forms-form fieldset {
    padding: 0 !important;
    margin: 0 !important;
}