.umbraco-forms {
  font-family: "proxima-nova", sans-serif;
}
.umbraco-forms-submitmessage {
  text-align: center;
  display: block;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: -0.5px;
  color: #2a2e31;
  max-width: 600px;
  margin: 0 auto;
}
.umbraco-forms-field.checkbox input, .umbraco-forms-field.multiplechoice input, .umbraco-forms-field.singlechoice input {
  border: 1px solid #0057b8 !important;
  display: inline-block !important;
  height: 18px !important;
  width: 18px !important;
}
.umbraco-forms-field.multiplechoice .checkboxlist {
  float: none !important;
  margin: 0.5em 0 !important;
  padding: 0 !important;
}
.umbraco-forms-field.multiplechoice .checkboxlist label {
  cursor: pointer;
  clear: none !important;
  float: none !important;
}
.umbraco-forms-field.singlechoice .radiobuttonlist {
  margin: 0.5em 0;
}
.umbraco-forms-field.singlechoice .radiobuttonlist label {
  cursor: pointer;
}
.umbraco-forms-field.dataconsent label {
  cursor: pointer;
}
.umbraco-forms-field.dataconsent .umbraco-forms-field-wrapper {
  margin: 0.5em 0;
}
.umbraco-forms-form .checkbox {
  cursor: initial;
  display: block;
  padding: 0;
  position: initial;
}
.umbraco-forms-caption {
  color: #0057b8;
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 27px;
}
.umbraco-forms-field {
  margin-bottom: 24px;
}
.umbraco-forms-tooltip {
  font-size: 14px;
  color: #2a2e31;
}
.umbraco-forms-form .field-validation-error {
  margin-top: 6px !important;
  color: #ef3340 !important;
}
.umbraco-forms-field-wrapper label {
  font-family: "proxima-nova", sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
}
.umbraco-forms-label {
  font-family: "proxima-nova", sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.3px;
}
.umbraco-forms-form input[type=text], .umbraco-forms-form input[type=number], .umbraco-forms-form input[type=email], .umbraco-forms-form input[type=tel], .umbraco-forms-form input[type=file], .umbraco-forms-form textarea, .umbraco-forms-form select, .umbraco-forms-form input.text {
  border-radius: 5px;
  border: solid 1px #dde3e7;
  background-color: white;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  letter-spacing: -0.48px;
  margin: 0.5em 0;
  min-height: 46px;
  min-width: 100% !important;
  padding: 13px 17px !important;
  width: 100% !important;
}
.umbraco-forms-form input[type=text]::placeholder, .umbraco-forms-form input[type=number]::placeholder, .umbraco-forms-form input[type=email]::placeholder, .umbraco-forms-form input[type=tel]::placeholder, .umbraco-forms-form input[type=file]::placeholder, .umbraco-forms-form textarea::placeholder, .umbraco-forms-form select::placeholder, .umbraco-forms-form input.text::placeholder {
  font-size: 16px;
  letter-spacing: -0.48px;
  text-align: left;
  color: #aab8c2;
}
.umbraco-forms-form input[type=checkbox], .umbraco-forms-form input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  border: 1px solid #0057b8;
  border-radius: 2px;
  cursor: pointer;
  height: 18px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: relative;
  vertical-align: middle;
  width: 18px;
  z-index: 0;
}
.umbraco-forms-form input[type=checkbox]::after, .umbraco-forms-form input[type=radio]::after {
  left: 5px;
  top: 1px;
  content: "";
  position: absolute;
  display: none;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}
.umbraco-forms-form input[type=checkbox]:checked:after, .umbraco-forms-form input[type=radio]:checked:after {
  display: block;
}
.umbraco-forms-form input[type=checkbox]:focus, .umbraco-forms-form input[type=radio]:focus {
  background: white;
}
.umbraco-forms-form input[type=checkbox]:checked, .umbraco-forms-form input[type=checkbox]:checked:focus, .umbraco-forms-form input[type=radio]:checked, .umbraco-forms-form input[type=radio]:checked:focus {
  background: #0057b8;
}
.umbraco-forms-form input[type=checkbox]:disabled, .umbraco-forms-form input[type=radio]:disabled {
  background: white;
  border: 1px solid #657786;
  pointer-events: none;
}
.umbraco-forms-form input[type=radio] {
  border-color: #657786 !important;
  border-radius: 50%;
}
.umbraco-forms-form input[type=radio]::after {
  display: none;
}
.umbraco-forms-form input[type=radio]:checked {
  border-color: transparent !important;
  background: #0057b8 border-box;
  box-shadow: 0 0 0 2px inset #fff;
}
.umbraco-forms-form input[type=radio]:checked::after {
  display: none;
}
.umbraco-forms-form input[type=file] {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
  text-align: center;
}
.umbraco-forms-form textarea {
  height: 127px !important;
}
.umbraco-forms-form .dropdown .umbraco-forms-field-wrapper {
  position: relative;
}
.umbraco-forms-form .dropdown .umbraco-forms-field-wrapper::before {
  border: 2px solid #0057b8;
  border-left-width: 0;
  border-top-width: 0;
  color: #0057b8;
  content: "";
  height: 12px;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: calc(50% - 3px);
  transform: translateY(-50%) rotate(45deg);
  width: 12px;
  z-index: 1;
}
.umbraco-forms-form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding-right: 46px !important;
  position: relative;
  text-overflow: ellipsis;
}
.umbraco-forms-form select:hover, .umbraco-forms-form select:focus {
  cursor: pointer;
  outline: none;
}
.umbraco-forms-form input.contourError, .umbraco-forms-form textarea.contourError, .umbraco-forms-form input.input-validation-error, .umbraco-forms-form textarea.input-validation-error {
  background: white !important;
  border-color: #ef3340 !important;
}
.umbraco-forms-form span.contourError, .umbraco-forms-form span.field-validation-error {
  color: #ef3340 !important;
  background: transparent !important;
  padding-left: 0px;
  font-size: 14px;
  letter-spacing: -0.48px;
  margin-top: 0px;
  margin-bottom: 15px;
  display: block;
}
.umbraco-forms-fieldset .row-fluid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}
.umbraco-forms-fieldset .row-fluid .col-md-1 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-1 {
    width: calc(8.3333333333% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-2 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-2 {
    width: calc(16.6666666667% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-3 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-3 {
    width: calc(25% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-4 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-4 {
    width: calc(33.3333333333% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-5 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-5 {
    width: calc(41.6666666667% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-6 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-6 {
    width: calc(50% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-7 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-7 {
    width: calc(58.3333333333% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-8 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-8 {
    width: calc(66.6666666667% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-9 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-9 {
    width: calc(75% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-10 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-10 {
    width: calc(83.3333333333% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-11 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-11 {
    width: calc(91.6666666667% - 12px);
  }
}
.umbraco-forms-fieldset .row-fluid .col-md-12 {
  width: 100%;
}
@media (min-width: 768px) {
  .umbraco-forms-fieldset .row-fluid .col-md-12 {
    width: calc(100% - 12px);
  }
}

.umbraco-forms-navigation {
  padding: 0px;
}
.umbraco-forms-navigation input[type=submit] {
  margin: 0 auto;
  min-width: 142px;
}

.umbraco-forms-form fieldset {
  padding: 0 !important;
  margin: 0 !important;
}